import { AssetFileType } from '../../types/assetType';
import { AxiosResponse } from 'axios';
import LinkWrapper from '../components/link-wrapper/LinkWrapper';
import React from 'react';
import { Kontent_Item_Rich_Text_Element_Link } from '../../types/graphql-types';

export function isLottie(type?: AssetFileType | string): boolean {
  return type === AssetFileType.JSON;
}

export function getAxiosErrorMessage(response: AxiosResponse): string {
  switch (response.status) {
    case 401:
      return 'Your password and email address did not match our records. Please try again';
    case 412:
      return "Your network address is not in the list of allowed addresses. Please contact your firm's IT support.";
    case 404:
      return 'Activation token has been used or has expired';
    case 409:
      return 'Email address is already in use';
    case 503:
      return 'We are performing maintenance. Please try again later.';
    default:
      return 'There was an error processing your request.';
  }
}

export function formatDate(date: string | Date): string {
  const month = [];
  month[0] = 'January';
  month[1] = 'February';
  month[2] = 'March';
  month[3] = 'April';
  month[4] = 'May';
  month[5] = 'June';
  month[6] = 'July';
  month[7] = 'August';
  month[8] = 'September';
  month[9] = 'October';
  month[10] = 'November';
  month[11] = 'December';
  const today = new Date(date);
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = month[today.getMonth()];
  const yyyy = today.getFullYear();
  return `${dd} ${mm} ${yyyy}`;
}

export function getHyperlinkUrl(type: string, url: string): string {
  switch (type) {
    case 'article':
      return `/blog/${url}`;
    case 'signup_branding':
      return `/${url}/register`;
    default:
      return url;
  }
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

export function createLink(
  linkId: string,
  label: string,
  links?: Kontent_Item_Rich_Text_Element_Link[] | null,
): JSX.Element {
  const link = links?.filter((link: Kontent_Item_Rich_Text_Element_Link) => {
    return link.link_id === linkId;
  });
  if (link && link[0]) {
    const url = getHyperlinkUrl(link[0].type, link[0]?.url_slug);
    return <LinkWrapper url={url} label={label} />;
  }
  return <></>;
}

export function getEstimateReadTime(wordCount: number, wordPerMinute: number): number | undefined {
  if (wordCount && wordPerMinute) return Math.round(wordCount / wordPerMinute);
  return;
}
