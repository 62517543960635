import React, { useEffect, useRef } from 'react';
import { ThemeWrapper } from '@tf/design-system-react';
import { gsap } from 'gsap';
import { TransitionState } from 'gatsby-plugin-transition-link';

import * as styles from './Template.module.scss';
import { ModeType } from '../../../types/commonTypes';
import { Helmet } from 'react-helmet';
import ico from '../../images/favicon-16x16.png';
import { getOverrides } from '../../assets/styles/themeOverrides';
import { breakpoints } from '../../assets/styles/theme';

// Export variables only return an object from the CSS module for some reason
// But the CSS variables has a class name that shouldn't be converted to a CSS module
import * as exportVariables from '../../assets/styles/variables/exportVariables.module.scss';
import '../../assets/styles/variables/cssVariables.scss';

export type TransitionStateType = 'exiting' | 'exited' | 'entering' | 'entered';

interface Props {
  children: NonNullable<React.ReactNode>;
  mode: ModeType;
  fullScreen?: boolean;
}

const Template = ({ children, mode, fullScreen }: Props): JSX.Element => {
  const content = useRef<HTMLDivElement>(null);

  const deprecatedProps = {
    breakpoints,
    getMuiComponentOverrides: getOverrides,
  };

  useEffect(() => {
    if (content && content.current) {
      const tl = gsap.timeline();
      const fadeUpElements = content.current.querySelectorAll('[data-animate="fade-up"]');
      const fadeUpFirst = content.current.querySelectorAll('[data-animate="fade-up-first"]');
      const fadeUpNodelay = content.current.querySelectorAll('[data-animate="fade-up-noDelay"]');
      const curtain = content.current.querySelectorAll('.transitionOverlay');

      tl.to(curtain, 0.2, {
        y: '-100%',
        height: 0,
        display: 'none',
        ease: 'Strong.easeOut',
      });

      fadeUpFirst.forEach((element) => {
        tl.fromTo(
          element,
          0.5,
          {
            y: 50,
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            ease: 'Power0.easeNone',
          },
          '-=0.1',
        );
      });

      fadeUpNodelay.forEach((element) => {
        tl.fromTo(
          element,
          0.5,
          {
            y: 50,
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            delay: 0.5,
            ease: 'Power0.easeNone',
          },
          '<',
        );
      });

      fadeUpElements.forEach((element) => {
        tl.fromTo(
          element,
          0.3,
          {
            y: 50,
            autoAlpha: 0,
          },
          {
            y: 0,
            autoAlpha: 1,
            ease: 'Power0.easeNone',
          },
          '-=0.1',
        );
      });
    }
  }, []);

  return (
    <ThemeWrapper {...{ deprecatedProps }} cssVariableOverrides={exportVariables}>
      <div
        data-component-name="Template"
        data-component-library="ot-kontent-gatsby"
        className={styles.container}
      >
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <link rel="icon" href={ico} />
        </Helmet>
        <TransitionState>
          {({ transitionStatus }: { transitionStatus: TransitionStateType }): JSX.Element => {
            if (transitionStatus === 'exiting') {
              const tl = gsap.timeline();
              tl.to(content.current, 0.9, {
                y: -100,
                ease: 'Strong.easeOut',
              });
            }
            return (
              <>
                {/*{!fullScreen && <Header className={styles.header} mode={mode} />}*/}
                <div className={fullScreen ? styles.routesFullScreen : styles.routes} ref={content}>
                  <div className={'transitionOverlay'} />
                  {children}
                </div>
                {/*{!fullScreen && <Footer className={styles.footer} />}*/}
              </>
            );
          }}
        </TransitionState>
      </div>
    </ThemeWrapper>
  );
};

Template.defaultProps = {
  children: null,
  mode: 'dark',
  fullScreen: false,
};

export default Template;
