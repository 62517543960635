import React from 'react';
import { Link } from 'gatsby';

export const copies = {
  welcome_back: 'Order Manager',
  login_intro: 'Log into your Openmarkets Order Manager account.',
  login_cta: 'Log in',
  forgot_password_cta: 'Forgot password',
  recover_password: 'Recover your password',
  recover_password_intro:
    "It's okay, we forget things too. Type in your email and we'll send you a link to change your password.",
  change_password: 'Change your password',
  change_password_intro:
    "Enter something special, unique to you and this login. Not your mum's birthday either – we looked that up.",
  change_password_cta: 'Change password',
  password_changed: 'Password changed!',
  password_changed_intro: 'Redirecting you to your account…',
  recovery_success_title: 'Verification link sent!',
  recovery_success_text: (
    <>
      Your link to change your password will be active for 24 hours.
      <br /> <br /> If you haven&apos;t received it yet, please make sure to check your spam folder
      or <Link to={'/reset'}>resend the link now.</Link>
    </>
  ),
  recovery_password_cta: 'Send verification link',
  recovery_success_cta: 'Go back to login',
};
