import { ThemeOptions } from '@mui/material/styles';

export const getOverrides = (theme: ThemeOptions): ThemeOptions['components'] => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.button.fontSize,
          padding: 'var(--padding--button)',
          fontWeight: 'bold',
          borderRadius: 'var(--border--radius--button)',
          transition: '0.2s',
          borderWidth: 'var(--border--width--button)',
          borderStyle: 'var(--border--style--button)',
          lineHeight: 'var(--line--height--button)',
          minHeight: 'var(--min--height--button)',
          minWidth: 'var( --min--width--button)',
        },
        outlined: {
          backgroundColor: 'transparent',
          color: '#fff',
          borderColor: '#fff',
          '&:hover': {
            color: '#000',
            backgroundColor: '#fff',
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
          borderColor: theme.palette.primary.main,
          '&:hover': {
            color: `${theme.palette.primary.main} !important`,
            backgroundColor: 'transparent !important',
          },
          '&:disabled': {
            backgroundColor: '#75757533 !important',
            color: '#FFFFFF !important',
            borderStyle: 'none',
          },
        },
        outlinedPrimary: {
          color: '#fff',
          borderColor: theme.palette.primary.main,
          '&:hover': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
          },
        },
        containedSecondary: {
          backgroundColor: theme.palette.secondary.main,
          color: '#fff',
          borderColor: theme.palette.secondary.main,
          '&:hover': {
            color: theme.palette.secondary.main,
            backgroundColor: 'transparent',
          },
        },
        outlinedSecondary: {
          color: '#fff',
          borderColor: theme.palette.secondary.main,
          '&:hover': {
            color: '#fff',
            backgroundColor: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            borderRadius: '50px',
          },
        },
        notchedOutline: {
          border: 0,
          '&:hover fieldset': {
            border: 0,
          },
          '&.Mui-focused': {
            border: 0,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: theme.palette.primary.main,
        },
      },
    },
  };
};
