export const seo = {
  login: {
    meta_title: 'Openmarkets Order Manager login',
    meta_description: 'Log into your Openmarkets Order Manager account.',
    og_title: 'Openmarkets Order Manager login',
    og_description: 'Log into your Openmarkets Order Manager account.',
    og_image:
      'https://openmarkets.com.au/hubfs/1635307/images/openmarkets-1200x628.png#keepProtocol',
    og_type: 'website',
    no_index: false,
  },
  password_change: {
    meta_title: 'Openmarkets | Change your password',
    meta_description: 'Openmarkets | Change your password',
    og_title: 'Openmarkets | Change your password',
    og_description: 'Openmarkets | Change your password',
    og_image:
      'https://openmarkets.com.au/hubfs/1635307/images/openmarkets-1200x628.png#keepProtocol',
    og_type: 'website',
    no_index: false,
  },
  password_recover: {
    meta_title: 'Recover your Openmarkets Order Manager password',
    meta_description:
      "Here's where you reset your password for the Order Manager to rule all Order Managers. You'll be managing orders again in no time.",
    og_title: 'Recover your Openmarkets Order Manager password',
    og_description:
      "Here's where you reset your password for the Order Manager to rule all Order Managers. You'll be managing orders again in no time.",
    og_image:
      'https://openmarkets.com.au/hubfs/1635307/images/openmarkets-1200x628.png#keepProtocol',
    og_type: 'website',
    no_index: false,
  },
};
