/* Breakpoints
------------------------------------------------------------------------------------------------ */
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 1180,
    xl: 1920,
  },
};

/* Animation
------------------------------------------------------------------------------------------------ */
const animation = {
  curtainBackground: '#FF0066',
  curtainDuration: '0.8',
  curtainDirection: 'up',
};

export { breakpoints, animation };
