export enum AssetType {
  IMAGE = 'image',
  LOTTIE = 'lottie',
}
export enum AssetFileType {
  JSON = 'application/json',
  SVG = 'image/svg+xml',
  PNG = 'image/png',
  // LOTTIE = 'application/octet-stream',
}

export const getAssetType = (assetType: AssetType): string => {
  switch (assetType) {
    case AssetType.IMAGE:
      return 'image';
    case AssetType.LOTTIE:
      return 'lottie';
  }
};
