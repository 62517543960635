import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  metadata: {
    seoMetaTitle?: string | null;
    seoMetaDescription?: string | null;
    seoNoIndex?: number | null;
    seoOgDescription?: string | null;
    seoOgImage?: string;
    seoOgTitle?: string | null;
    seoOgType?: string;
  };
}

const HelmetWrapper: FunctionComponent<Props> = ({
  metadata: {
    seoMetaTitle,
    seoMetaDescription,
    seoNoIndex,
    seoOgDescription,
    seoOgImage,
    seoOgTitle,
    seoOgType,
  },
  children,
}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{seoMetaTitle}</title>
      {seoMetaDescription && <meta name="description" content={seoMetaDescription} />}
      {seoOgTitle && <meta property="og:title" content={seoOgTitle} />}
      {seoOgDescription && <meta property="og:description" content={seoOgDescription} />}
      {seoOgImage && <meta property="og:image" content={seoOgImage} />}
      {seoOgType && <meta property="og:type" content={seoOgType} />}
      {seoNoIndex && <meta name="robots" content="noindex" />}
      {children}
    </Helmet>
  );
};

export default HelmetWrapper;
