import React, { FunctionComponent } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Container } from '@tf/design-system-react';
import Lottie from 'lottie-react';

import { ModeType } from '../../../types/commonTypes';
import Logo from '../../assets/img/logo.svg';

import * as styles from './BackGroundCover.module.scss';
import wavingFlowerAnimation from '../../assets/json/opentrader-waving-flower.json';

interface Props {
  children: NonNullable<React.ReactNode>;
  mode: ModeType;
  background: string;
  curveColor?: string;
  withCurve: boolean;
  className?: string;
  withDecorator?: boolean;
  decoratorColor?: string;
  addHeaderLogo?: boolean;
  animatedDecorator?: boolean;
}

const BackGroundCover: FunctionComponent<Props> = ({
  children,
  className,
  background,
  mode,
  decoratorColor,
  addHeaderLogo,
  animatedDecorator = false,
}) => {
  const style = {
    background,
  };

  return (
    <section
      className={`${addHeaderLogo ? styles.container : styles.containerWithoutLogo} ${
        className ? className : ''
      } `}
      style={style}
    >
      {addHeaderLogo === true && (
        <Container>
          <div className={styles.logoWrapper}>
            <AniLink
              to="/"
              cover
              direction="up"
              bg="linear-gradient(180deg, #FFFFFF 0%, #F8DFEB 100%)"
              duration={'0.8'}
            >
              <img alt="Order Manager" src={Logo} className={styles.logo} />
            </AniLink>
          </div>
        </Container>
      )}
      <Container className={`${mode} ${styles.children}`}>{children}</Container>
      <Container className={styles.decoratorContainer}>
        <div className={styles.decoratorWrapper}>
          {animatedDecorator && (
            <Lottie className={styles.decorator} animationData={wavingFlowerAnimation} />
          )}

          {!animatedDecorator && (
            <svg
              className={styles.decorator}
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 880 800"
            >
              <path
                fill={decoratorColor}
                fillOpacity="0.09"
                d="M453.0838,297.0368a161.82,161.82,0,0,0,8.9041-23.9578A171.1245,171.1245,0,0,0,501.2081,319a196.4027,196.4027,0,0,0,25.0929,17.257c-9.2294,12.3168-15.1959,26.1524-16.3475,41.9574-1.6691,22.9815,7.16,45.4373,22.0135,65.1477-47.8739,2.4533-94.321,18.1582-125.7224,42.358a78.7444,78.7444,0,0,0,14.6535-31.3429c6.6006-31.1094-6.8677-62.8946-22.1054-87.6618,32.0775-22.0052,48.7252-57.7041,54.2911-69.6789m372.9448-54.9253c-41.4237-27.9467-92.9859-15.8133-139.55,2.0778a453.8817,453.8817,0,0,0,8.8623-44.6361c5.7327-36.7254,11.3821-72.9-10.7147-103.4585C655.1774,55.3889,595.1118,50.4321,555.3908,59.82c-44.9033,10.6145-84.232,44.4025-102.4738,88.4712-9.3545-29.6239-22.4474-62.0934-37.3762-99.1192-3.0876-7.6521-8.72-21.0789-22.147-31.2845C359.4471-7.9229,296.6278,2.8,263.5991,36.3628c-14.2112,14.4448-18.3668,28.5141-30.984,94.8968-6.6844,35.1565-12.1084,63.8875-15.9719,87.1861C140.9894,189.431,65.152,190.0735,28.7605,236.6791-3.033,277.3932,1.19,345.6117,14.2407,389.2716c20.962,70.1378,72.9332,104.41,96.7408,120.1146a222.1279,222.1279,0,0,0,35.9242,19.2347,230.548,230.548,0,0,0-36.7921,65.2979c-3.9971,11.0317-39.0366,107.731,9.6716,164.4838C175.47,823.2831,307.5594,797.431,384.24,739.051c3.2795-2.4951,34.4555-26.8284,59.5983-61.9265C473.62,704.3535,541.772,758.2023,622.9,735.8216c58.9808-16.264,122.8183-72.3074,120.9406-143.5134-.9761-36.6753-19.0759-65.2144-41.44-86.66,49.0839-13.1931,88.5881-47.39,111.2108-67,27.546-23.8744,57.3117-49.6681,61.4507-92.0262,3.9639-40.5973-16.94-82.8552-49.0336-104.51"
              />
            </svg>
          )}
        </div>
      </Container>
    </section>
  );
};

export default BackGroundCover;
