// extracted by mini-css-extract-plugin
export var colorPrimaryLight = "#ff3385";
export var colorPrimaryMain = "#f06";
export var colorPrimaryDark = "#cc0052";
export var colorPrimaryContrastText = "#fff";
export var colorSecondaryLight = "#203c6b";
export var colorSecondaryMain = "#142644";
export var colorSecondaryDark = "#08101d";
export var colorSecondaryContrastText = "#fff";
export var colorTextSecondary = "#616161";
export var fontWeightH1 = "700";
export var fontSizeH1 = "2rem";
export var fontLineHeightH1 = "1.2";
export var fontLetterSpacingH1 = "-0.03562em";
export var fontSizeH2 = "2.5rem";
export var fontWeightH2 = "700";
export var fontSizeH3 = "1.875rem";
export var fontWeightH3 = "700";
export var fontSizeH4 = "1.25rem";
export var fontWeightH4 = "700";
export var fontSizeH5 = "1rem";
export var fontWeightH5 = "700";
export var fontSizeH6 = ".875rem";
export var fontSizeBody1 = "14px";
export var fontLineHeightBody1 = "1.8";
export var fontSizeButton = "14px";
export var fontWeightButton = "bold";
export var borderRadiusButton = "28px";
export var borderWidthButton = "2px";
export var borderStyleButton = "solid";
export var paddingButton = "14px 34px";